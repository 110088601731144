import Scrollbar from 'smooth-scrollbar';

export class ZazCard {

    constructor(element) {
        this.element = element;
        setTimeout(() => {
            this.run();
        }, 500);
    }

    adjust() {
        const cardContent = this.element.querySelector('.front .card-content');
        const finalHeight = `${cardContent.clientHeight}px`;
        this.element.style.height = finalHeight;
        this.element.querySelector('.back').style.height = finalHeight;
    }

    run() {
        this.adjust();
        Scrollbar.init(this.element.querySelector('.back .card-content .track'));

        window.addEventListener('resize', () => {
            this.adjust();
        });

        this.element.addEventListener('click', () => {
            this.element.classList.toggle('is-flipped');
        });

        if (!!window.IntersectionObserver) {
            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('is-semi');
                        setTimeout(() => {
                            entry.target.classList.remove('is-semi');
                        }, 500);
                    }
                });
            });
            observer.observe(this.element);
        }

    }

}