import CircleType from 'circletype';

export class ArcText {

    constructor(element) {
        this.element = element;
        this.run();
    }

    run() {
        this.circleType = new CircleType(this.element);
        this.circleType.radius(250);
    }

}