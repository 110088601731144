export class PingZaz {

    constructor(element) {
        this.element = element;
        this.run();
    }

    run() {

        const endpoint = 'https://northamerica-northeast1-zaztro.cloudfunctions.net/screenshot';

        const pattern = /\/zaz\/(.*)(\?|\/|)/;
        const url = window.location.href;

        const results = url.match(pattern);
        const id = results[1] || '';

        fetch(endpoint, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        });

    }

}