export class ClassLoader {

    static run(modules) {
        this.modules = modules;
        this.modules.forEach((module) => {
            ClassLoader.loadModule(module);
        });
    }

    static loadModule(module) {
        let domTarget = `[data-module="${module.target}"]`;
        let domElements = document.querySelectorAll(domTarget);
        domElements.forEach((domElement) => {
            domElement.module = new module.module(domElement);
        });
    }

}
