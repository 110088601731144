export class ZazForm {

    constructor(element) {
        this.element = element;
        this.run();
    }

    check() {

        const city = this.element.querySelector('input[name="city"]');
        const lat = this.element.querySelector('input[name="lat"]').value;
        const lng = this.element.querySelector('input[name="lng"]').value;

        if (lat == '' || lng == '') {
            city.value = '';
            city.focus();
            return false;
        }

        return true;

    }

    run() {

        this.element.addEventListener('submit', (e) => {
            if (!this.check()) {
                e.preventDefault();
            }
        });

    }

}