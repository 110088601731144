export class ZaztroNavbar {

    constructor(element) {
        this.element = element;
        this.hamburger = element.querySelector('.hamburger');
        this.mobileMenu = element.querySelector('.mobile-menu');
        this.run();
    }

    run() {
        this.hamburger.addEventListener('click', () => {
            this.hamburger.classList.toggle('is-active');
            this.mobileMenu.classList.toggle('is-active');
            document.querySelector('body').classList.toggle('is-not-scrollable');
        });
    }

}