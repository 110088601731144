export class CityPicker {

    constructor(element) {
        this.element = element;
        this.lat = this.element.parentElement.querySelector('input[name="lat"]');
        this.lng = this.element.parentElement.querySelector('input[name="lng"]');
        this.run();
    }

    run() {

        this.autocomplete = new google.maps.places.Autocomplete(this.element, {
            fields: ['geometry'],
            types: ['locality']
        });

        this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            this.lat.value = place.geometry.location.lat();
            this.lng.value = place.geometry.location.lng();
        });

        this.element.addEventListener('keyup', () => {
            this.lat.value = '';
            this.lng.value = '';
        });

    }

}