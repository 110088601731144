import './scss/main.scss';

import { ClassLoader } from './js/utils/ClassLoader';
import { CityPicker } from './js/CityPicker';
import { ZazForm } from './js/ZazForm';
import { ArcText } from './js/ArcText';
import { ZaztroNavbar } from './js/ZaztroNavbar';
import { ZazCard } from './js/ZazCard';
import { PingZaz } from './js/PingZaz';

// SAL
import sal from 'sal.js'

const run = async () => {
    sal();
    ClassLoader.run([
        {
            target: 'CityPicker',
            module: CityPicker
        },
        {
            target: 'ZazForm',
            module: ZazForm
        },
        {
            target: 'ArcText',
            module: ArcText
        },
        {
            target: 'ZaztroNavbar',
            module: ZaztroNavbar
        },
        {
            target: 'ZazCard',
            module: ZazCard
        },
        {
            target: 'PingZaz',
            module: PingZaz
        }
    ]);
};

(async () => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', async () => {
            await run();
        });
    } else {
        await run();
    }
})();
